import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const FlavorSquare = () => (
  <StaticQuery
    query={graphql`
    query CakeFlavorsSquareImageQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
        frontmatter {
          flavorsImage {
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    }
  `}
    render={data => {
      return <Img fluid={data.markdownRemark.frontmatter.flavorsImage.childImageSharp.fluid} alt="Cake Flavors" />
    }}
  />
)

export default FlavorSquare
