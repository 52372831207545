import React from "react"
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

SwiperCore.use([Navigation])

const params = {
  slidesPerView: 2,
  navigation: {

  },

  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
  },
}

const SecondInstagramSlider = () => (
  <StaticQuery
    query={graphql`
      query SecondInstagramImages {
        allFile(
          filter: { absolutePath: { regex: "/instagram/" } }
          sort: { order: ASC, fields: base }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fixed(width: 530) {
                  ...GatsbyImageSharpFixed_withWebp
                  originalName
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const photos = data.allFile.edges
      const images = []

      photos.forEach((photo, i) => {
        images.push(
          <SwiperSlide>
            <Img
              fixed={photo.node.childImageSharp.fixed}
              key={i}
              alt={"Instagram Photo " + i}
            />
          </SwiperSlide>
        )
      })

      return <Swiper {...params}>{images}</Swiper>
    }}
  />
)

export default SecondInstagramSlider
